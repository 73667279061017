<template>
    <div class="col-md-12">
        <div class="modal fade modal_cust show" v-if="modalId=='viewBranch'" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" style="display: block;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
                <div class="modal-content">
                    <div class="modal-header no-print">
                        <div class="row">
                        <div class="col-sm-6">
                            <h5 class="modal-title" id="exampleModalCenterTitle">
                            Branch Detail - {{datas.branch_id}}
                            </h5>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <!-- <div class="bill-head-icon mr-3">
                            <i class="fas fa-print" title="Print"></i>
                            </div>
                            <div class="bill-head-icon mr-3">
                            <i class="fas fa-edit"></i>
                            </div> -->
                            <div
                            class="bill-head-icon mr-1"
                            @click="$store.dispatch('modalClose','viewBranch')"
                             title="Close">
                            <i class="fas fa-times"></i>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="bg-theam-secondary">
                        <div class="modal-body row">
                        <div class="col-sm-6 pd-r-7-5">
                            <div class="row">
                            <div class="col-sm-12">
                                <div class="group mb-3">
                                    <label class="group-label">Basic Information</label>
                                    <div class="group-attribute-container">
                                        <div class="row m-0 mb-1">
                                            <div class="col-sm-5 m-0 p-0">Branch Code</div>
                                            <div class="col-sm-7 m-0 p-0">: {{datas.branch_id}}</div>
                                        </div>
                                        <div class="row m-0 mb-1">
                                            <div class="col-sm-5 m-0 p-0">Branch Name</div>
                                            <div class="col-sm-7 m-0 p-0">: {{datas.name}}</div>
                                        </div>
                                        <div class="row m-0 mb-1">
                                            <div class="col-sm-5 m-0 p-0">Address</div>
                                            <div class="col-sm-7 m-0 p-0">: {{datas.address}}</div>
                                        </div>
                                        <div class="row m-0 mb-1">
                                            <div class="col-sm-5 m-0 p-0">Contact No.</div>
                                            <div class="col-sm-7 m-0 p-0">: {{datas.phone}}</div>
                                        </div>
                                        <div class="row m-0 mb-1">
                                            <div class="col-sm-5 m-0 p-0">Email</div>
                                            <div class="col-sm-7 m-0 p-0">: {{datas.email}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="group mb-3">
                                    <label class="group-label">Control Information</label>
                                    <div class="group-attribute-container">
                                        <div class="row mx-0">
                                            <div class="col-sm-5 m-0 p-0">Status</div>
                                            <div class="col-sm-7 m-0 p-0">: <span v-if="datas.status == 1">Active</span><span v-else>In-Active</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-sm-6 pd-l-7-5">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="group mb-3">
                                        <label class="group-label">Cash Information</label>
                                        <div class="group-attribute-container">
                                            <div class="row mx-0">
                                                <div class="col-sm-5 m-0 p-0">Opening Cash</div>
                                                <div class="col-sm-7 m-0 p-0">: NPR {{datas.payment_methods[0].opening_balance}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="group" id="description">
                                        <label class="group-label">Description</label>
                                        <div
                                            class="group-attribute-container overflow-scroll"
                                            style="height: 5.2rem"> 
                                            {{datas.description}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
 import { mapGetters } from "vuex";
export default {
    data(){
        return{
            datas:{},
        }
    },
     computed: {
            ...mapGetters(["processing", 
            "eventMessage",
            "dataLists",
            "dataLists2",
            "modalId",
            "modalState",
            "user",
            "modalMode",
            "dataId"]),
            ...mapGetters("branchs",["branchDatas"]),
        },
    methods:{
    },
    watch:{
        modalId(value){
            if(value == 'viewBranch'){
                let id = this.dataId;
                let details = this.branchDatas.find(function (data) {
                    return data.id == id;
                });
                this.datas = {...details};
            }
        }
    }
}
</script>