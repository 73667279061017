<template>
    <div class="col-md-12">
        <div class="modal fade modal_cust show" v-if="modalId=='editBranch'" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" style="display: block;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Edit Branch</h5>
                        <a class="close" title="Close"  @click="$store.dispatch('modalClose','editBranch')">
                            <span aria-hidden="true">×</span>
                        </a>
                    </div>
                    <form @submit.prevent="branchEdit" method="post" class="bg-theam-secondary pt-4" enctype="multipart/form-data">
                        <div class="modal-body row">
                            <div class="col-md-6 mb-0">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="group mb-3">
                                        <label class="group-label">Basic Information</label>
                                        <div class="group-attribute-container">
                                            <div class="row mb-2">
                                                <div class="col-md-5 mr-0 pr-0">Branch Code <i class="error">*</i></div>
                                                <div class="col-md-7 input-group ml-0 pl-0">
                                                    <div class="wd-100p">
                                                        <input type="text" class="form-control" placeholder="Branch Code" v-model="formData.branch_id" @input="upper($event)" @keyup="validateBranchCode(formData.branch_id,formData.id)" required>
                                                    </div>
                                                    <span class="tx-danger" v-if="unique_error == true">Branch Code should be unique </span>
                                                </div>
                                                </div>
                                                <div class="row mb-2">
                                                <div class="col-md-5 mr-0 pr-0">Branch Name <i class="error">*</i></div>
                                                <div class="col-md-7 input-group ml-0 pl-0">
                                                    <input type="text" class="form-control" placeholder="Name" v-model="formData.name" required>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-5 mr-0 pr-0">Branch Address <i class="error">*</i></div>
                                                <div class="col-md-7 input-group ml-0 pl-0">
                                                    <input type="text" class="form-control" placeholder="Address" v-model="formData.address" required>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-5 mr-0 pr-0">Phone Number <i class="error">*</i></div>
                                                <div class="col-md-7 input-group ml-0 pl-0">
                                                    <input type="text" class="form-control" placeholder="Phone Number" v-model="formData.phone">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-5 mr-0 pr-0">Email <i class="error">*</i></div>
                                                <div class="col-md-7 input-group ml-0 pl-0">
                                                    <input type="email" class="form-control" placeholder="Email Address" v-model="formData.email">
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="group">
                                            <label class="group-label">Control Information</label>
                                            <div class="group-attribute-container">
                                                <div class="row">
                                                    <div class="col-md-5 mr-0 pr-0">Status<i class="error">*</i></div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <select name="status" class="form-control" v-model="formData.status" required>
                                                            <option value="1">Active</option>
                                                            <option value="0">In-Active</option>    
                                                        </select> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="group mb-3" >
                                            <label class="group-label">Cash Information</label>
                                            <div class="group-attribute-container">
                                                <div class="row">
                                                    <div class="col-md-5 mr-0 pr-0">Opening Balance</div>
                                                    <div class="col-md-7 ml-0 pl-0" v-if="formData.balance_editable == 0">
                                                        <input type="number" min="0" class="form-control" v-model="formData.opening_balance">
                                                    </div>
                                                    <div class="col-md-7 ml-0 pl-0" v-else>
                                                        <span class="tx-danger"> Branch already used. Cannot update Cash Balance</span>
                                                    </div>
                                                    <div class="group-note mt-2 mb-2 mx-1 px-2">
                                                        <span>Note: </span> On Creating new branch Cash as payment method for that branch will be automatically created so Please add cash balance here. 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group">
                                            <label class="group-label">Description</label>
                                            <div class="group-attribute-container">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <textarea name="description" v-model="formData.description" style="height:6rem" class="form-control"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 text-right mt-3">
                                <button class="btn btn-primary" :disabled="disableSubmitButton"><span v-if="unique_error || checking_unique">Submit</span><span v-else>{{ disableSubmitButton ? 'Submitting....' : 'Submit'}}</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 import { mapGetters } from "vuex";
 import Services from "./Services/Services";
 import _ from 'lodash';
export default {
    data(){
        return{
            formData:{
                id:'',
                branch_id:'',
                name:'',
                address:'',
                phone:'',
                email:'',
                branch_manager_id:'',
                description:'',
                user_id:'',
                status:'',
                opening_balance:0,
                balance_editable:'',
            },
            disableSubmitButton :false,
            unique_error:false,
            checking_unique:false,

        }
    },
     computed: {
            ...mapGetters(["processing", "eventMessage","dataLists","dataLists2","modalId","modalState","user","modalMode","dataId"]),
            ...mapGetters("branchs",["branchDatas","branchElements"])
        },
        mounted() {
          this.formData.user_id = this.user.id;
        },
    methods:{
        upper(e) {
          this.formData.branch_id = e.target.value.toUpperCase();
        },
        branchEdit(){
             if (this.modalId == "editBranch") {
                this.disableSubmitButton = true;
                this.loading = true; 
                Services.updateBranch(this.formData,this.formData.id).then(res=>{
                    this.$store.dispatch("modalClose");
                    this.$emit('edit-branch');
                     this.setNotification(res.data.success_message);
                }).catch(err=>{
                    this.error=false;
                    console.log(err);
                    if(err.response.status == 422){
                        let error = Object.values(err.response.data.errors).flat();
                        this.setNotification(error);
                    }else{
                       let error =err.response.data.error_message;
                        this.setNotification(error);
                    }
                }).finally(()=>{
                this.loading = false;
                this.disableSubmitButton=false;
                })
            }   
        },
        setNotification(msg){
            this.$store.dispatch("enableMessage");
            this.$store.commit("setEventMessage",msg);
        },
        validateBranchCode:_.debounce(function(code,id){
             this.checking_unique = true;
            if(code != ''){
                let values = {
                    'code':code,
                    'id':id,
                };
                Services.validateBranchCode(values).then(res=>{
                    this.disableSubmitButton = res.data.data;
                    this.unique_error = res.data.data;
                }).catch(err=>{
                    console.log(err);
                });
                this.checking_unique = false;
            }
            
        }, 1000),  
    },
    watch:{
        modalId(value) { 
            if (value == "editBranch") {
                let id = this.dataId;
                let details = this.branchDatas.find(function (data) {
                    return data.id == id;
                });
                this.formData = {...details};
                this.disableSubmitButton = false;
                this.unique_error=false;
                this.formData.opening_balance = this.formData.payment_methods[0].opening_balance;

            }
        },
    }
}
</script>