<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newBranch'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Add Branch</h5>
            <a
              class="close"
              @click="$store.dispatch('modalClose', 'newBranch')"
              title="Close"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="branchCreate"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 mb-0">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            Branch Code <i class="error">*</i>
                          </div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <div class="wd-100p">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Branch Code"
                                v-model="formData.branch_id"
                                @input="upper($event)"
                                @keyup="
                                  validateBranchCode(formData.branch_id, 0)
                                "
                                required
                              />
                            </div>
                            <span class="tx-danger" v-if="unique_error == true"
                              >Branch Code should be unique
                            </span>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            Branch Name <i class="error">*</i>
                          </div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Name"
                              v-model="formData.name"
                              id="branch_name"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            Branch Address <i class="error">*</i>
                          </div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Address"
                              id="branch_address"
                              v-model="formData.address"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            Phone Number <i class="error">*</i>
                          </div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Phone Number"
                              id="branch_phone_number"
                              v-model="formData.phone"
                              required
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">
                            Email <i class="error">*</i>
                          </div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Email Address"
                              id="branch_email"
                              v-model="formData.email"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Control Information</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">
                            Status<i class="error">*</i>
                          </div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              name="status"
                              class="form-control"
                              id="branch_status"
                              v-model="formData.status"
                              required
                            >
                              <option value="1">Active</option>
                              <option value="0">In-Active</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Cash Information</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Opening Balance</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              min="0"
                              class="form-control"
                              id="branch_opening_balance"
                              v-model="formData.opening_balance"
                            />
                          </div>
                          <div class="group-note mt-2 mb-2 mx-1 px-2">
                            <span>Note: </span> On Creating new branch Cash as
                            payment method for that branch will be automatically
                            created so Please add cash balance here.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              name="description"
                              v-model="formData.description"
                              id="branch_description"
                              style="height: 6rem"
                              class="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button class="btn btn-primary" :disabled="disableSubmitButton">
                  <span v-if="unique_error || unique_check">Submit</span
                  ><span v-else>{{
                    disableSubmitButton ? "Submitting...." : "Submit"
                  }}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import _ from "lodash";
export default {
  data() {
    return {
      formData: {
        id: "",
        branch_id: "",
        name: "",
        address: "",
        phone: "",
        email: "",
        branch_manager_id: "",
        description: "",
        user_id: "",
        status: 1,
        opening_balance: 0,
      },
      disableSubmitButton: false,
      unique_error: false,
      unique_check: false,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists2",
      "modalId",
      "modalState",
      "user",
      "modalMode",
      "dataId",
    ]),
    ...mapGetters("branchs", ["branchElements"]),
  },
  mounted() {
    this.formData.user_id = this.user.id;
  },
  methods: {
    upper(e) {
      this.formData.branch_id = e.target.value.toUpperCase();
    },
    branchCreate() {
      if (this.modalId == "newBranch") {
        this.disableSubmitButton = true;
        this.loading = true;
        Services.createNewBranch(this.formData)
          .then((res) => {
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
            this.$emit("store-branch");
          })
          .catch((err) => {
            this.error = false;
            console.log(err);
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              let error = err.response.data.error_message;
              this.setNotification(error);
              console.log(err);
            }
          })
          .finally(() => {
            this.loading = false;
            this.disableSubmitButton = false;
          });
      }
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    validateBranchCode: _.debounce(function (code, id) {
      this.unique_check = false;
      if (code != "") {
        if (code != "") {
          let values = {
            code: code.toUpperCase(),
            id: id,
          };
          Services.validateBranchCode(values)
            .then((res) => {
              this.disableSubmitButton = res.data.data;
              this.unique_error = res.data.data;
            })
            .catch((err) => {
              console.log(err);
            });
        }
        this.unique_check = false;
      }
    }, 2000),
    clearData() {
      this.formData.branch_id = "";
      this.formData.name = "";
      this.formData.address = "";
      this.formData.phone = "";
      this.formData.email = "";
      this.formData.status = 1;
      this.formData.branch_manager_id = "";
      this.formData.description = "";
      this.formData.opening_balance = 0;
    },
  },
  watch: {
    modalId(value) {
      if (value == "newBranch") {
        this.clearData();
      }
    },
  },
};
</script>