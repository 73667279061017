<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-md-12">
        <div class="row mg-0">
          <div class="col-6">
            <div class="content-header pd-l-0">
              <div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Branch</a>
                    </li>
                    <li
                      class="breadcrumb-item text-capitalize active"
                      aria-current="page"
                    >Manage Branches</li>
                  </ol>
                </nav>
                <h4 class="content-title content-title-xs">Manage Branches</h4>
              </div>
            </div>
            <!-- content-header -->
          </div>
          <!-- col -->
          <div class="col-6 tx-right">
            <button
              v-if="checkSinglePermission('create_branch')"
              type="button"
              class="btn btn-primary mg-t-8 mg-b-0"
              @click="$store.dispatch('modalWithMode', {
                            id: 'newBranch',
                            mode: 'create',
                            })"
            >New Branch</button>
          </div>
          <!-- col -->
        </div>
        <!-- row -->
        <div class="bg-theam-secondary table-search-head pt-0">
          <div class="row">
            <div class="filter-input col-9 d-block mr-0 pr-0">
              <select v-model="params.size" @change="searchFilter" class="mr-2 mg-t-5">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
            <div class="col-3 ml-0 pl-0 mg-t-5">
              <input
                type="text"
                v-model="params.searched"
                @keyup="searchFilter"
                class="float-right"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <div class="overflow-x-auto">
          <table class="table table2 table-hover table-striped" id="exampleTable">
            <thead>
              <tr>
                <th scope="col" class="text-center">S.N.</th>
                <th scope="col" class="text-center">Branch Code</th>
                <th scope="col" class="text-center">Name</th>
                <th scope="col" class="text-center">Phone</th>
                <th scope="col" class="text-center">Email</th>
                <th scope="col" class="text-center">Status</th>
                <th
                  scope="col"
                  class="text-center"
                  v-if="checkIfAnyPermission('create_branch','view_branch')"
                >Action</th>
              </tr>
            </thead>
            <tbody v-if="!loading && branchDatas.length > 0">
              <tr v-for="(branch, index) in branchDatas" :key="index">
                <th scope="row" class="text-center">{{pageSerialNo+index}}.</th>
                <td class="text-center">{{branch.branch_id}}</td>
                <td class="text-center">{{branch.name}}</td>
                <td class="text-center">{{branch.phone}}</td>
                <td class="text-center">{{branch.email}}</td>
                <td class="text-center">
                  <span v-if="branch.status==1">Active</span>
                  <span v-else>In-Active</span>
                </td>
                <td class="text-right" v-if="checkIfAnyPermission(['view_branch','create_branch'])">
                  <a
                    href="javascript:;"
                    v-if="checkSinglePermission('view_branch')"
                    class="mr-3"
                    title="View"
                    :id="'branchView'+branch.id"
                  >
                    <i
                      class="fas fa-eye tx-success"
                      @click.prevent="viewDetail('viewBranch', branch.id)"
                    ></i>
                  </a>
                  <a
                    href="javascript:;"
                    v-if="checkSinglePermission('edit_branch')"
                    class="mr-3"
                    title="Edit"
                    :id="'branchEdit'+branch.id"
                  >
                    <i class="fa fa-edit" @click.prevent="edit('editBranch', branch.id)"></i>
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="!loading && branchDatas.length == 0">
              <tr>
                <td colspan="11" style="text-align:center;">No records found.</td>
              </tr>
            </tbody>
            <tbody v-else-if="loading">
              <tr>
                <td colspan="11" style="text-align:center;">Loading.....</td>
              </tr>
            </tbody>
            <tbody v-else-if="error">
              <tr>
                <td colspan="11" style="text-align:center;">{{error_message}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <BranchCreate @store-branch="getData()" />
        <BranchEdit @edit-branch="getData()" />
        <BranchView />
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BranchCreate from "./create";
import BranchEdit from "./edit";
import BranchView from "./view";
import Services from "./Services/Services";
import Paginate from "vuejs-paginate";
import _ from "lodash";
export default {
  data() {
    return {
      params: {
        size: 10,
        searched: "",
        offset: 0,
      },
      totalPageCount: 0,
      pageSerialNo: 1,
      loading: true,
      error: false,
      error_message: "",
      page: 1,
    };
  },
  computed: {
    ...mapGetters(["icons", "dataLists", "dataLists4", "modalMode", "dataId"]),
    ...mapGetters("branchs", ["branchDatas"]),
  },
  props: ["formData"],
  mounted() {
    this.getData();
  },
  components: {
    BranchCreate,
    Paginate,
    BranchEdit,
    BranchView,
  },
  methods: {
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.size + 1;
      }
      this.params.offset = (page - 1) * this.params.size;
      this.getData();
    },
    getData() {
      this.loading = true;
      Services.getFilteredBranchData(this.params)
        .then((res) => {
          this.$store.commit("branchs/setBranchDatas", res.data.data.data);
          this.loading = false;
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((error) => {
          if(!Object.prototype.hasOwnProperty.call(error,"response.data.error_message")) {
            if (error.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
          this.error = true;
          this.loading = false;
        });
    },
    drop(id) {
      this.$swal({
        title: "Do you really want to delete Branch ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          Services.deleteBranch(id)
            .then((response) => {
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              this.getData();
            })
            .catch((error) => {
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    viewDetail(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    searchFilter: _.debounce(function () {
      this.loading = true;
      this.page = 1;
      this.pageSerialNo = 1;
      this.params.offset = 0;
      Services.getFilteredBranchData(this.params)
        .then((res) => {
          this.$store.commit("branchs/setBranchDatas", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((err) => {
          this.error = true;
          this.loading = false;
          if (err.response.data.error_message == "Access Denied.") {
            this.error_message = "Access Denied !!!";
          } else {
            this.error_message = "Error Fetching data from the server.";
          }
          let error = err.response.data.error_message;
          this.setNotification(error);
        });
    }, 1000),
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
  },
  watch: {},
};
</script>
<style scoped>
</style>